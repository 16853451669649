import React, { createContext, useContext, useEffect, useState } from 'react';

type XpFilters = {
  expertise: string | 'none';
  profile: string | 'all';
};

interface XpContext {
  filters: XpFilters;
  setFilters: (f: XpFilters) => void;
  activeTestimonial?: string;
  setActiveTestimonial: (s: string | undefined) => void;
}

const XpContext = createContext<XpContext | undefined>(undefined);

export const XpContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [activeTestimonial, setActiveTestimonial] = useState<string | undefined>();
  const [filters, setFilters] = useState({
    expertise: 'none',
    profile: 'all',
  });

  return (
    <XpContext.Provider
      value={{
        filters,
        setFilters,
        activeTestimonial,
        setActiveTestimonial,
      }}
    >
      {children}
    </XpContext.Provider>
  );
};

export const useXpContext = () => {
  const context = useContext(XpContext);
  if (!context) throw new Error('No XpContext.Provider found when calling useXpContext');
  return context;
};
