import { Link } from 'gatsby';
import React, { HTMLAttributeAnchorTarget } from 'react';
import { usePageContext } from '../context/pageContext';
import { CTAColor } from './Cta';

interface StyledLinkProps {
  to: string;
  target?: HTMLAttributeAnchorTarget;
  children: React.ReactNode;
  className?: string;
  onClick?: any;
  dataType?: CTAColor;
  activeClassName?: string;
  state?: any;
  noLink?: boolean;
}

export const LocalLink: React.FC<StyledLinkProps> = ({
  to,
  target = '_self',
  children,
  className,
  onClick,
  dataType,
  activeClassName,
  state,
  noLink = false,
}) => {
  const { lang, defaultLang } = usePageContext();
  const isExternal = to?.indexOf('/') !== 0;
  const localePath = `${lang !== defaultLang ? `/${lang}${to}` : to}`;

  return (
    <>
      {noLink ? (
        <button
          className={`${className}`}
          onClick={onClick}
          data-type={dataType}
        >
          {children}
        </button>
      ) : isExternal ? (
        <a
          href={to}
          target={'default'}
          className={`${className}`}
          onClick={onClick}
          data-type={dataType}
        >
          {children}
        </a>
      ) : (
        <Link
          to={localePath}
          onClick={onClick}
          className={`${className}`}
          data-type={dataType}
          activeStyle={{
            fontWeight: 'bold',
          }}
          activeClassName={activeClassName}
          state={state}
        >
          {children}
        </Link>
      )}
    </>
  );
};
