import React, { useState } from 'react';
import * as styles from './burger.module.scss';

interface Props {
  setIsOpened: (value: boolean) => void;
  isOpened: boolean;
}

export const Burger: React.FC<Props> = ({ isOpened, setIsOpened }) => {
  return (
    <div
      className={styles.isScrolled}
      onClick={() => {
        setIsOpened(!isOpened);
      }}
    >
      <div className={isOpened ? styles.isOpened : styles.isClosed}>
        <span></span>
      </div>
    </div>
  );
};
