import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Maybe, SanityMenuItem, SanityPage } from 'graphql-types';
import * as styles from './headerDesktop.module.scss';
import { LocalLink } from '../html-tag/localLink';
import { usePageContext } from '../context/pageContext';
import ArrowDownOrange from '../../../static/svg/arrow-down-orange.svg';
import { SubMenuDesktop } from '../menu/SubMenuDesktop';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  HeaderDesktopQuery,
  SanityPageExpertise,
  SanityCountrySettings,
} from '../../../graphql-types';
import { Flags } from './Flags';
import { findTranslation } from '../../utils/findTranslation';
import { constructSlug } from '../../utils/constructSlug';
import { useGeoContext } from '../context/useGeoContext';
import { isCountryAllowed } from '../../utils/isCountryAllowed';

interface Props {}

export const HeaderDesktop: React.FC<Props> = () => {
  const { lang, defaultLang, dark } = usePageContext();
  const { country } = useGeoContext();
  const data = useStaticQuery<HeaderDesktopQuery>(HEADERDESKTOP);

  const url = typeof window !== 'undefined' ? window.location.href : '';
  const logo = dark
    ? data.sanityHeader?.logoCompanyHeaderLight
    : data.sanityHeader?.logoCompanyHeaderDark;

  return (
    <nav className={styles.wrapper}>
      <LocalLink
        className={styles.logo}
        to={'/'}
      >
        <GatsbyImage
          image={logo?.logoImg?.asset?.gatsbyImageData ?? ''}
          loading={'eager'}
          alt={logo?.logoAlt ?? ''}
          objectFit={'contain'}
        />
      </LocalLink>
      <div className={styles.nav}>
        {data?.sanityHeader?.menuHeader?.menuItemsList
          ?.filter((item) =>
            isCountryAllowed(
              country,
              item?.correspondingInternalPage?.correspondingPage
                ?.countrySettings as SanityCountrySettings,
            ),
          )
          ?.map((item, index) => {
            let slug: Maybe<string> | undefined;

            if (item?.correspondingInternalPage?.experience) slug = `/experience`;
            else {
              slug = constructSlug({
                destination: item?.correspondingInternalPage?.correspondingPage as
                  | SanityPage
                  | SanityPageExpertise,
              });
            }

            return (
              <div
                className={styles.menu}
                key={`item-${index}`}
              >
                <div className={`${styles.menuItemInternal}`}>
                  {(item?.correspondingInternalPage?.menuSubItemsList?.length ?? 0) === 0 ? (
                    <LocalLink
                      to={slug ?? ''}
                      className={`${dark ? styles.itemWhite : styles.itemBlack} ${
                        url.includes(slug ? slug : '') ? styles.activeLink : styles.inactiveLink
                      }`}
                    >
                      {findTranslation(item?._rawLabelItem, lang, defaultLang)}
                    </LocalLink>
                  ) : (
                    <>
                      <div
                        className={`${dark ? styles.itemWhite : styles.itemBlack} ${
                          url.includes(slug ? slug : '') ? styles.activeLink : styles.inactiveLink
                        }`}
                      >
                        {findTranslation(item?._rawLabelItem, lang, defaultLang)}
                        <ArrowDownOrange />
                      </div>
                      {
                        <div className={styles.subItem}>
                          <SubMenuDesktop data={item as SanityMenuItem} />
                        </div>
                      }
                    </>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      <div className={styles.flags}>
        <Flags />
      </div>
    </nav>
  );
};

export const HEADERDESKTOP = graphql`
  query HeaderDesktop {
    sanityVideoTitleTextSection {
      activeMedia
    }

    sanityHeader {
      ...headerFragment
    }
  }
`;
