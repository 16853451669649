// extracted by mini-css-extract-plugin
export var activeLink = "headerDesktop-module--activeLink--f7c66";
export var flags = "headerDesktop-module--flags--658bd";
export var itemBlack = "headerDesktop-module--itemBlack--3f6b6";
export var itemWhite = "headerDesktop-module--itemWhite--30e54";
export var logo = "headerDesktop-module--logo--3b052";
export var menu = "headerDesktop-module--menu--66acd";
export var menuItemInternal = "headerDesktop-module--menuItemInternal--2c86b";
export var nav = "headerDesktop-module--nav--5df12";
export var subItem = "headerDesktop-module--subItem--84f2b";
export var wrapper = "headerDesktop-module--wrapper--d51fe";