import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import * as styles from './input.module.scss';

export const Input = ({
  input,
  meta,
  label,
  placeholder,
  dataType,
  children
}: FieldRenderProps<string, HTMLElement>) => {

  return (
    <div className={styles.wrapper}>
      <label>{label}</label>
      <div className={styles.content}>
        <input
          {...input}
          type={input.type}
          placeholder={placeholder}
          name={label}
          onChange={input.onChange}
          className={`${styles.input} ${
            (meta.error || meta.submitError) && meta.touched && styles.inputW
          }`}
          data-type={dataType}
        />
        {children}
      </div>

      <p className={styles.warning}>
        {(meta.error || meta.submitError) && meta.touched && meta.error}
      </p>
    </div>
  );
};
