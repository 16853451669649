import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { HeaderQuery } from 'graphql-types';
import * as styles from './header.module.scss';
import { Burger } from '../burger/Burger';
import { GatsbyImage } from 'gatsby-plugin-image';
import { LocalLink } from '../html-tag/localLink';

interface Props {
  isOpened: boolean;
  setIsOpened: (b: boolean) => void;
}

export const Header: React.FC<Props> = ({ isOpened, setIsOpened }) => {
  const data = useStaticQuery<HeaderQuery>(HEADER);

  return (
    <nav className={`${styles.wrapper} ${isOpened && styles.fullHeight}`}>
      <div className={styles.headbandBg}>
        <LocalLink
          className={styles.logo}
          to={'/'}
          onClick={() => {
            setIsOpened(!isOpened);
          }}
        >
          <GatsbyImage
            alt={data?.sanityHeader?.logoCompanyHeaderDark?.logoAlt ?? ''}
            image={data?.sanityHeader?.logoCompanyHeaderDark?.logoImg?.asset?.gatsbyImageData}
            className={`${styles.logoBlack} ${styles.logo}`}
          />
        </LocalLink>
        <div className={styles.burger}>
          <Burger
            isOpened={isOpened}
            setIsOpened={setIsOpened}
          />
        </div>
      </div>
    </nav>
  );
};

export const HEADER = graphql`
  query Header {
    sanityFooter {
      logoCompanyFooter {
        logoAlt
        logoImg {
          asset {
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
    }
    sanityHeader {
      ...headerFragment
    }
  }
`;
