import { useState, useEffect } from "react";

export type WindowSize = {
  width: number;
  height: number;
  ratio: number;
};

function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
    ratio: 1,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state

      const newRatio = document.body.clientWidth / document.body.clientHeight;
      setWindowSize({
        width: document.body.clientWidth,
        height: document.body.clientHeight,
        ratio: newRatio,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default useWindowSize;