import { SanityCountrySettings } from '../../graphql-types';

export const isCountryAllowed = (country: string, countries?: SanityCountrySettings) => {
  if (!country) return true;

  if ((countries?.allowed?.length ?? 0) === 0 && (countries?.forbidden?.length ?? 0) === 0)
    return true;

  if (
    (countries?.forbidden?.length ?? 0) > 0 &&
    countries?.forbidden?.findIndex((e) => e?.list?.includes(country)) !== -1
  )
    return false;

  if (
    (countries?.allowed?.length ?? 0) > 0 &&
    !(countries?.allowed?.findIndex((e) => e?.list?.includes(country)) !== -1)
  )
    return false;

  return true;
};
