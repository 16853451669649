import { graphql, useStaticQuery } from 'gatsby';
import { FooterQuery, SanityFooter, SanityPage } from 'graphql-types';
import * as styles from './footer.module.scss';
import React from 'react';
import { Newsletter } from '../newsletter/Newsletter';
import { usePageContext } from '../context/pageContext';
import { Accordion } from '../accordion/Accordion';
import { CTA } from '../html-tag/Cta';
import { LocalLink } from '../html-tag/localLink';
import { GatsbyImage } from 'gatsby-plugin-image';
import { findTranslation } from '../../utils/findTranslation';
import { constructSlug } from '../../utils/constructSlug';

interface Props {}

export const Footer: React.FC<Props> = () => {
  const data = useStaticQuery<FooterQuery>(FOOTER);
  const { lang, defaultLang } = usePageContext();

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonsContainer}>
        <CTA
          onClick={() => {}}
          label={findTranslation(data?.sanityFunctionButton?._rawContactUs, lang, defaultLang)}
          to={constructSlug({ destination: data?.sanityFooter?.contactPage as SanityPage }) ?? ''}
          dataType={'black'}
          className={styles.button}
        />
        <CTA
          onClick={() => {}}
          label={findTranslation(data?.sanityFunctionButton?._rawSpecialist, lang, defaultLang)}
          dataType={'orange'}
          className={styles.button}
          to={
            constructSlug({ destination: data?.sanityFooter?.findTherapistPage as SanityPage }) ??
            ''
          }
        />
      </div>

      <Accordion data={data?.sanityFooter as SanityFooter} />

      <Newsletter data={data?.sanityFooter as SanityFooter} />

      <div className={styles.legalLinks}>
        <LocalLink
          to={constructSlug({ destination: data?.sanityFooter?.privacyPage as SanityPage }) ?? ''}
        >
          <p>{'Privacy Policy'}</p>
        </LocalLink>
        <p>{'|'}</p>
        <LocalLink
          to={constructSlug({ destination: data?.sanityFooter?.legalsPage as SanityPage }) ?? ''}
        >
          <p>{'Legals'}</p>
        </LocalLink>
      </div>
      <LocalLink
        to={'/'}
        className={styles.logo}
      >
        <GatsbyImage
          alt={data?.sanityFooter?.logoCompanyFooter?.logoAlt ?? ''}
          image={data?.sanityFooter?.logoCompanyFooter?.logoImg?.asset?.gatsbyImageData ?? ''}
        />
      </LocalLink>
      <p>{data.sanityFooter?.copyRightCompany}</p>
    </div>
  );
};

export const FOOTER = graphql`
  query Footer {
    sanityFunctionButton {
      _rawContactUs(resolveReferences: { maxDepth: 10 })
      _rawSpecialist(resolveReferences: { maxDepth: 10 })
    }
    sanityFooter {
      ...footerFragment
    }
  }
`;
