import React, { useContext, createContext, useEffect, useState, useCallback, useMemo } from 'react';
import { getGeolocation } from '../../api/getGeolocation';
import useLocalStorage from '../hooks/useLocalStorage';

interface IGeoContext {
  country: string;
}

const GeoContext = createContext<IGeoContext | undefined>(undefined);

export const GeoContextProvider: React.FC<{
  children: any;
}> = ({ children }) => {
  const [country, setCountry] = useLocalStorage<string>('country', '');
  const [ip, setIp] = useState<string>('');

  const getIp = useCallback(async () => {
    try {
      const response = await fetch('https://api.ipify.org/?format=json');
      const data = await response.json();
      setIp(data?.ip);
    } catch (e) {
      console.log('error fetching IP');
    }
  }, [setIp]);

  const getGeoloc = useCallback(async () => {
    if (ip) {
      const countryGeo = await getGeolocation({ ip });
      if (countryGeo) setCountry(countryGeo);
    }
  }, [setCountry, ip]);

  useEffect(() => {
    getIp();
  }, []);

  useEffect(() => {
    getGeoloc();
  }, [ip]);

  const value = useMemo(() => ({ country }), [country]);

  return <GeoContext.Provider value={value}>{children}</GeoContext.Provider>;
};

export const useGeoContext = () => {
  const geoContext = useContext(GeoContext);
  if (!geoContext) throw new Error('No PageContext.Provider found when calling usePageContext.');
  return geoContext;
};
