import React, { useEffect, useState } from 'react';
import { usePageContext } from '../context/pageContext';
import useScrollLock from '../hooks/useScrollLock';
import { Menu } from '../menu/Menu';
import { Footer } from './Footer';
import { FooterDesktop } from './FooterDesktop';
import { Header } from './Header';
import { HeaderDesktop } from './HeaderDesktop';
import * as styles from './layout.module.scss';
import { useGeoContext } from '../context/useGeoContext';
import { navigate } from 'gatsby';
import { isCountryAllowed } from '../../utils/isCountryAllowed';

interface Props {
  children: React.ReactNode;
}

export const Layout: React.FC<Props> = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    const scrollbarWidth =
      typeof window !== `undefined` ? window.innerWidth - document.body.clientWidth : 0;
    document.body.style.setProperty('--viewportWidth', `calc(100vw - ${scrollbarWidth}px)`);
  }, []);

  const { experience, home, countries, lang, defaultLang, originalPath } = usePageContext();
  const { country } = useGeoContext();

  useEffect(() => {
    // if (!isCountryAllowed(country, countries)) navigate(`/${lang !== defaultLang ? lang : ''}`);
  }, [country, originalPath]);

  // useEffect(() => {
  //   if (menuOpen) lockScroll();
  //   else unlockScroll();
  // }, [menuOpen]);

  const header = (
    <header>
      <div className={styles.mobile}>
        <Header
          isOpened={menuOpen}
          setIsOpened={setMenuOpen}
        />
      </div>
      <div
        className={styles.desktop}
        style={
          experience
            ? {
                background: 'black',
              }
            : {}
        }
      >
        <HeaderDesktop />
      </div>
    </header>
  );

  return (
    <>
      <div className={styles.wrapper}>
        {!home && header}
        <div
          className={styles.menu}
          data-open={menuOpen}
        >
          {/* {menuOpen && ( */}
          <Menu
            isOpened={menuOpen}
            setIsOpened={setMenuOpen}
          />
          {/* )} */}
        </div>

        <main className={styles.main}>{children}</main>
        <footer>
          <Footer />
          <FooterDesktop />
        </footer>
      </div>
    </>
  );
};
