import { useEffect, useState } from 'react';

type Setter<T> = React.Dispatch<React.SetStateAction<T>>;

function useLocalStorage<T>(key: string, initialValue: T): [T, Setter<T>] {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') return initialValue;

    try {
      const item = window.localStorage.getItem(key);
      const res: T = item ? JSON.parse(item) : initialValue;
      return res;
    } catch (error) {
      console.warn(`Error: Localstorage ${key}`);
      return initialValue;
    }
  });

  /**
   * UseEffect to update localstorage on setValue
   */
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const updateLocalStorage = () => {
      if (storedValue !== undefined) {
        window.localStorage.setItem(key, JSON.stringify(storedValue));
      } else {
        window.localStorage.removeItem(key);
      }
    };

    try {
      updateLocalStorage();
    } catch (e) {
      console.warn('Error: Updating Localstorage ', e);
    }
    // added key to fix eslint make sure it doesn't break hook
  }, [storedValue, key]);

  return [storedValue, setStoredValue];
}

export default useLocalStorage;
