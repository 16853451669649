import React from 'react';
import { usePageContext } from '../context/pageContext';
import * as styles from './flags.module.scss';
import FR from '../../../static/svg/winback_icon_country_fr_38x25.svg';
import GB from '../../../static/svg/winback_icon_country_en_38x25.svg';
import ES from '../../../static/svg/winback_icon_country_sp_38x25.svg';
import IT from '../../../static/svg/winback_icon_country_it_38x25.svg';
import GE from '../../../static/svg/winback_icon_country_de_38x25.svg';
import Arrow from '../../../static/svg/arrow-down-orange.svg';
import { Link } from 'gatsby';

interface Props {}

const options = [
  { value: 'en', label: 'English', icon: <GB /> },
  { value: 'fr', label: 'Français', icon: <FR /> },
  { value: 'it', label: 'Italiano', icon: <IT /> },
  { value: 'es', label: 'Español', icon: <ES /> },
  { value: 'de', label: 'Deutsch', icon: <GE /> },
];

export const Flags: React.FC<Props> = () => {
  const { lang, setLang, originalPath, dark, slugs } = usePageContext();
  const current = options.find((el) => el.value === lang);

  return (
    <div className={styles.wrapper}>
      <div className={dark ? styles.flagsWhite : styles.flagsBlack}>
        {current?.label}
        <div className={styles.icon}>
          <Arrow />
        </div>
      </div>

      {
        <div className={styles.countries}>
          {options
            .filter((el) => el !== current)
            .map((country, index) => {
              return (
                <Link
                  to={
                    country.value === 'en'
                      ? `${originalPath}`
                      : `/${country.value}${slugs?.[country.value] ?? ''}`
                  }
                  className={styles.country}
                  key={`country-${index}`}
                  onClick={() => {
                    setLang(country.value);
                  }}
                >
                  {/* {country.icon} */}
                  <p>{country.label}</p>
                </Link>
              );
            })}
        </div>
      }
    </div>
  );
};
