// extracted by mini-css-extract-plugin
export var box = "footerDesktop-module--box--1245d";
export var company = "footerDesktop-module--company--cc35e";
export var content = "footerDesktop-module--content--fccde";
export var first = "footerDesktop-module--first--56a22";
export var logo = "footerDesktop-module--logo--b7927";
export var news = "footerDesktop-module--news--8b6b5";
export var second = "footerDesktop-module--second--438d6";
export var secondContent = "footerDesktop-module--secondContent--b27b9";
export var social = "footerDesktop-module--social--1270e";
export var wrapper = "footerDesktop-module--wrapper--2523c";