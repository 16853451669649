import * as styles from './newsletter.module.scss';
import React, { useState } from 'react';
import { usePageContext } from '../context/pageContext';
import { Input } from '../html-tag/Input';
import { Field, Form } from 'react-final-form';
import { SanityFooter } from 'graphql-types';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { findTranslation } from '../../utils/findTranslation';

interface Props {
  data: SanityFooter;
}

export const Newsletter: React.FC<Props> = ({ data }) => {
  const { lang, defaultLang } = usePageContext();
  const [initialValues, setInitialValues] = useState({});
  const onSubmit = (values: any) => {
    const email = values.email;
    addToMailchimp(email);
  };

  const required = (values: string) => (values ? undefined : 'This field has a error');

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className={styles.form}
          >
            <fieldset>
              <Field
                name="newsletter"
                component={Input}
                type="email"
                label={findTranslation(data?._rawTitleNewsletter, lang, defaultLang)}
                placeholder={findTranslation(data?._rawPlaceholderNewsletter, lang, defaultLang)}
                value={findTranslation(data?._rawTitleNewsletter, lang, defaultLang)}
                validate={required}
              >
                <button
                  className={styles.button}
                  type="submit"
                >
                  {findTranslation(data?._rawSendNewsletter, lang, defaultLang)}
                </button>
              </Field>
              <Field
                name="optin"
                component="input"
                validate={required}
              >
                {({ input, meta }) => (
                  <div className={styles.wrapperCheckbox}>
                    <div
                      className={`${styles.checkbox} ${
                        meta.error && meta.touched && styles.checkboxW
                      }`}
                    >
                      <input
                        {...input}
                        type="checkbox"
                        value="checked"
                        className={styles.optin}
                        id="winter"
                      />
                    </div>

                    <label className={`${meta.error && meta.touched && styles.labelW}`}>
                      {findTranslation(data?._rawOptinNewsletter, lang, defaultLang)}
                    </label>
                  </div>
                )}
              </Field>
            </fieldset>
          </form>
        );
      }}
    />
  );
};
