import React, { createContext, useContext } from 'react';
import useWindowSize, { WindowSize } from '../hooks/useWindowSize';

const WindowSizeContext = createContext<WindowSize | undefined>(undefined);

export const WindowSizeContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { width, height, ratio } = useWindowSize();

  return (
    <WindowSizeContext.Provider
      value={{
        width,
        height,
        ratio,
      }}
    >
      {children}
    </WindowSizeContext.Provider>
  );
};

export const useWindowSizeContext = () => {
  const context = useContext(WindowSizeContext);
  if (!context)
    throw new Error('No WindowSizeContext.Provider found when calling useWindowSizeContext');
  return context;
};
