import React, { useState } from 'react';
import { usePageContext } from '../context/pageContext';
import * as styles from './subMenu.module.scss';
import ArrowLeftOrange from '../../../static/svg/arrow-left-orange.svg';
import { SanityCountrySettings, SanityMenuItem } from 'graphql-types';
import { LocalLink } from '../html-tag/localLink';
import ArrowDownOrange from '../../../static/svg/arrow-down-orange.svg';
import { constructSlug } from '../../utils/constructSlug';
import {
  SanityPage,
  SanityPageExpertise,
  SanityMenuSubItem,
  SanityPageProduct,
} from '../../../graphql-types';
import { findTranslation } from '../../utils/findTranslation';
import { isCountryAllowed } from '../../utils/isCountryAllowed';
import { useGeoContext } from '../context/useGeoContext';

interface Props {
  data: SanityMenuItem;
  setGoSubMenu: (value: number) => void;
  isOpened: boolean;
  setIsOpened: (value: boolean) => void;
}

export const SubMenu: React.FC<Props> = ({ data, isOpened, setIsOpened, setGoSubMenu }) => {
  const { lang, defaultLang } = usePageContext();
  const { country } = useGeoContext();
  const [goSubTechMenu, setGoSubTechMenu] = useState<number>(-1);
  const [selectedSubMenu, setSelectedSubMenu] = useState<SanityMenuSubItem>();

  const handleClickOnSubmenu = (item, index) => {
    setSelectedSubMenu(item);
    setGoSubTechMenu(index);
  };

  const allLink = data?.correspondingInternalPage?.allSubPages ? (
    <li>
      <LocalLink
        to={
          constructSlug({
            destination: data?.correspondingInternalPage?.correspondingPage as
              | SanityPage
              | SanityPageExpertise,
          }) ?? ''
        }
        onClick={() => {
          setIsOpened(!isOpened);
        }}
      >
        {data?.correspondingInternalPage?._rawAllLinkName?.[lang] ?? 'All'}
      </LocalLink>
    </li>
  ) : null;

  if (goSubTechMenu === -1) {
    return (
      <div className={styles.wrapper}>
        <ul>
          <div
            className={styles.title}
            onClick={() => {
              setGoSubMenu(-1);
            }}
          >
            <ArrowLeftOrange />
            {findTranslation(data?._rawLabelItem, lang, defaultLang)}
          </div>
          {data?.correspondingInternalPage?.allLinkPosition === 'top' && allLink}
          {data?.correspondingInternalPage?.menuSubItemsList
            ?.filter((item) =>
              isCountryAllowed(
                country,
                item?.correspondingSubPage?.countrySettings as SanityCountrySettings,
              ),
            )
            ?.map((menuSubItem, index) =>
              (menuSubItem?.correspondingSubPageProduct?.length ?? 0) > 0 ? (
                <>
                  <li key={`menuSubItem-${index}`}>
                    <div
                      onClick={() => handleClickOnSubmenu(menuSubItem, index)}
                      className={styles.techMenu}
                    >
                      {findTranslation(menuSubItem?._rawLabelSubItem, lang, defaultLang)}
                      <ArrowDownOrange />
                    </div>
                  </li>
                </>
              ) : (
                <li key={`menuSubItem-${index}`}>
                  <LocalLink
                    to={
                      constructSlug({
                        destination: menuSubItem?.correspondingSubPage as
                          | SanityPageExpertise
                          | SanityPage,
                      }) ?? ''
                    }
                    onClick={() => {
                      setIsOpened(!isOpened);
                      setGoSubMenu(-1);
                    }}
                  >
                    {findTranslation(menuSubItem?._rawLabelSubItem, lang, defaultLang)}
                  </LocalLink>
                </li>
              ),
            )}
          {data?.correspondingInternalPage?.allLinkPosition === 'bottom' && allLink}
        </ul>
      </div>
    );
  }

  let subMenuAllLink: any = null;
  if (selectedSubMenu?.allSubPages) {
    subMenuAllLink = (
      <li>
        <LocalLink
          to={
            constructSlug({
              destination: selectedSubMenu.correspondingSubPage as SanityPage | SanityPageExpertise,
            }) ?? ''
          }
          onClick={() => {
            setIsOpened(!isOpened);
            setGoSubMenu(-1);
          }}
        >
          {selectedSubMenu?._rawAllLinkName?.[lang] ?? 'All'}
        </LocalLink>
      </li>
    );
  }

  return (
    <div className={styles.wrapper}>
      <ul>
        <div
          className={styles.title}
          onClick={() => {
            setGoSubTechMenu(-1);
          }}
        >
          <ArrowLeftOrange />
          {selectedSubMenu && findTranslation(selectedSubMenu?._rawLabelSubItem, lang, defaultLang)}
        </div>
        {selectedSubMenu?.allLinkPosition === 'top' && subMenuAllLink}

        {selectedSubMenu?.correspondingSubPageProduct
          ?.filter((menuItem) =>
            isCountryAllowed(country, menuItem?.countrySettings as SanityCountrySettings),
          )
          ?.map((menuItem) => {
            let title: string = '';
            switch (menuItem?.__typename) {
              case 'SanityPage':
                title = findTranslation(menuItem._rawPageTitle, lang, defaultLang);
                break;
              case 'SanityPageExpertise':
                title = findTranslation(menuItem._rawPageExpertiseTitle, lang, defaultLang);
                break;
              case 'SanityPageProduct':
                title = findTranslation(menuItem?._rawPageProductTitle, lang, defaultLang);
                break;
              default:
                null;
            }

            return (
              <li>
                <LocalLink
                  to={
                    constructSlug({
                      destination: menuItem as SanityPageProduct | SanityPageExpertise | SanityPage,
                    }) ?? ''
                  }
                  onClick={() => {
                    setIsOpened(!isOpened);
                    setGoSubTechMenu(-1);
                    setGoSubMenu(-1);
                  }}
                  className={styles.menuSubItem}
                >
                  {title}
                </LocalLink>
              </li>
            );
          })}
      </ul>
      {selectedSubMenu?.allLinkPosition === 'bottom' && subMenuAllLink}
    </div>
  );
};
