import { graphql, useStaticQuery } from 'gatsby';
import { AllPagesQuery, SanityPage } from 'graphql-types';
import React from 'react';
import slugify from 'slugify';
import { SanityPageExpertise, SanityPageProduct } from '../../graphql-types';
import { usePageContext } from '../components/context/pageContext';
import { findTranslation } from './findTranslation';

interface Props {
  destination?: SanityPage | SanityPageExpertise | SanityPageProduct;
}

export const constructSlug = ({ destination }: Props) => {
  const { allSanityPage } = useStaticQuery<AllPagesQuery>(QUERY);
  const { lang, defaultLang } = usePageContext();

  if (!destination?._type) return '/';
  if (destination._type === 'page') {
    destination = destination as SanityPage;
    const destinationSlug = findTranslation(
      destination._rawPageSlug ?? destination.pageSlug,
      lang,
      defaultLang,
    );

    return `/${slugify(destinationSlug ?? '')}`;
  } else if (destination._type === 'pageExpertise') {
    let parentPage = allSanityPage.nodes.find(
      (page) =>
        page.pagesExpertiseList?.findIndex(
          (pageExpertise) => pageExpertise?.id === destination?.id,
        ) !== -1,
    ) as SanityPage;

    const parentPageSlug = findTranslation(
      parentPage?._rawPageSlug ?? parentPage?.pageSlug,
      lang,
      defaultLang,
    )?.toLowerCase();

    destination = destination as SanityPageExpertise;
    let destinationPath = findTranslation(
      destination?._rawPageExpertiseSlug ?? destination?.pageExpertiseSlug,
      lang,
      defaultLang,
    )?.toLowerCase();

    return `${parentPageSlug ? `/${slugify(parentPageSlug)}` : ''}/${slugify(
      destinationPath ?? '',
    )}`;
  } else if (destination._type === 'pageProduct') {
    let familyPage: SanityPageExpertise | undefined;
    let parentPage = allSanityPage.nodes.find((page) => {
      familyPage = page.pagesExpertiseList?.find((pageExpertise) => {
        return (
          pageExpertise?.pagesProductList?.findIndex(
            (pageProduct) => pageProduct?.id === destination?.id,
          ) !== -1
        );
      }) as SanityPageExpertise | undefined;
      return familyPage !== undefined;
    });

    const parentPageSlug = findTranslation(
      parentPage?._rawPageSlug,
      lang,
      defaultLang,
    )?.toLowerCase();

    const familyPageSlug = findTranslation(
      familyPage?._rawPageExpertiseSlug,
      lang,
      defaultLang,
    )?.toLowerCase();

    const slug = findTranslation(
      (destination as SanityPageProduct)?._rawPageProductSlug ??
        (destination as SanityPageProduct).pageProductSlug,
      lang,
      defaultLang,
    )?.toLowerCase();

    const parentPageSlugify = parentPageSlug ? `/${slugify(parentPageSlug)}` : '';
    const familyPageSlugify = familyPageSlug ? `/${slugify(familyPageSlug)}` : '';
    const slugSlugify = slug ? `/${slugify(slug)}` : '';

    return `${parentPageSlugify}${familyPageSlugify}${slugSlugify}`;
  }
};

const QUERY = graphql`
  query allPages {
    allSanityPage {
      nodes {
        id
        _rawPageSlug(resolveReferences: { maxDepth: 10 })
        pagesExpertiseList {
          id
          _rawPageExpertiseSlug(resolveReferences: { maxDepth: 10 })
          pagesProductList {
            id
            _rawPageProductSlug(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    }
  }
`;
