import React, { useState, useContext, createContext, useEffect } from 'react';
import { SanityCountrySettings } from '../../../graphql-types';

interface IPathState {
  path: string;
}

interface IMenuState {
  menuStateOpen: boolean;
}

interface IFormPopState {
  popupState: string;
}

interface IWindowState {
  width: number;
  height: number;
}

interface IPageContext {
  menuState: IMenuState;
  setMenuState: (value: IMenuState) => void;

  formPopUpState: IFormPopState;
  setFormPopUpState: (value: IFormPopState) => void;

  pathState: IPathState;
  setPathState: (value: IPathState) => void;

  lang: string;
  setLang: (lang: string) => void;

  windowState: IWindowState;
  setWindowState: (value: IWindowState) => void;

  experience: boolean;
  testimonial: boolean;
  home: boolean;

  originalPath: string;
  originalPathCom: string;
  defaultLang: string;
  dark?: boolean;
  xpOpen: boolean;
  setXpOpen: (e: boolean) => void;
  slugs: any;
  countries: SanityCountrySettings;
}

const PageContext = createContext<IPageContext | undefined>(undefined);
const defaultLang = 'en';

export const PageContextProvider: React.FC<{
  pageContext: any;
  children: any;
}> = ({ pageContext, children }) => {
  const [xpOpen, setXpOpen] = useState<boolean>(false);
  const [menuState, setMenuState] = useState<IMenuState>({
    menuStateOpen: false,
  });

  const [formPopUpState, setFormPopUpState] = useState<IFormPopState>({
    popupState: 'closed',
  });

  const [lang, setLang] = useState(pageContext.lang);

  const [windowState, setWindowState] = useState<IWindowState>({
    width: 0,
    height: 0,
  });

  return (
    <PageContext.Provider
      value={{
        ...pageContext,

        menuState: menuState,
        setMenuState: setMenuState,

        lang,
        setLang,
        defaultLang,

        formPopUpState: formPopUpState,
        setFormPopUpState: setFormPopUpState,

        windowState: windowState,
        setWindowState: setWindowState,

        xpOpen,
        setXpOpen,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => {
  const pageContext = useContext(PageContext);
  if (!pageContext) throw new Error('No PageContext.Provider found when calling usePageContext.');
  return pageContext;
};
