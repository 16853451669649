import React, { FC } from 'react';
import * as styles from './cta.module.scss';
import ArrowRight from '../../../static/svg/arrow-right-black.svg';
import { LocalLink } from './localLink';

export type CTAColor =
  | 'multi'
  | 'orange'
  | 'black'
  | 'violet'
  | 'red'
  | 'green'
  | 'blue'
  | 'white'
  | string;

interface Props {
  label: string;
  onClick?: () => void;
  dataType: CTAColor;
  to: string;
  target?: string;
  state?: any;
  className?: string;
  reverse?: boolean;
  noLink?: boolean;
}

export const CTA: FC<Props> = ({
  label,
  className,
  onClick,
  dataType,
  to,
  target,
  state,
  reverse = false,
  noLink = false,
}) => {
  return (
    <div className={`${className}`}>
      <LocalLink
        to={to}
        onClick={onClick}
        className={`${styles.cta}`}
        dataType={reverse ? `${dataType}white` : dataType}
        target={target}
        state={state}
        noLink={noLink}
      >
        <p>{label}</p>
        <ArrowRight />
      </LocalLink>
    </div>
  );
};
