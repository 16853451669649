import React from 'react';
import * as styles from './SubTechMenuDesktop.module.scss';
import { LocalLink } from '../html-tag/localLink';
import { usePageContext } from '../context/pageContext';
import {
  SanityMenuSubItem,
  SanityPageExpertise,
  SanityPageProduct,
  SanityPage,
  SanityCountrySettings,
} from '../../../graphql-types';
import { constructSlug } from '../../utils/constructSlug';
import { findTranslation } from '../../utils/findTranslation';
import { useGeoContext } from '../context/useGeoContext';
import { isCountryAllowed } from '../../utils/isCountryAllowed';

interface Props {
  menuSubItem: SanityMenuSubItem;
}

export const SubTechMenuDesktop: React.FC<Props> = ({ menuSubItem }) => {
  const { lang, defaultLang } = usePageContext();
  const { country } = useGeoContext();

  const allLink = menuSubItem?.allSubPages ? (
    <li>
      <LocalLink
        to={
          constructSlug({
            destination: menuSubItem?.correspondingSubPage as SanityPageExpertise,
          }) ?? ''
        }
      >
        {menuSubItem?._rawAllLinkName?.[lang] ?? 'All'}
      </LocalLink>
    </li>
  ) : null;

  return (
    <div className={styles.wrapper}>
      <ul>
        {menuSubItem?.allLinkPosition === 'top' && allLink}
        {menuSubItem?.correspondingSubPageProduct
          ?.filter((menuItem) =>
            isCountryAllowed(country, menuItem?.countrySettings as SanityCountrySettings),
          )
          ?.map((menuItem, index) => {
            let title: string = '';
            switch (menuItem?.__typename) {
              case 'SanityPage':
                title = findTranslation(menuItem._rawPageTitle, lang, defaultLang);
                break;
              case 'SanityPageExpertise':
                title = findTranslation(menuItem._rawPageExpertiseTitle, lang, defaultLang);
                break;
              case 'SanityPageProduct':
                title = findTranslation(menuItem?._rawPageProductTitle, lang, defaultLang);
                break;
              default:
                null;
            }
            return (
              <li>
                <LocalLink
                  to={
                    constructSlug({
                      destination: menuItem as SanityPageProduct | SanityPageExpertise | SanityPage,
                    }) ?? ''
                  }
                  className={styles.menuSubItem}
                >
                  {title}
                </LocalLink>
              </li>
            );
          })}
        {menuSubItem?.allLinkPosition === 'bottom' && allLink}
      </ul>
    </div>
  );
};
