import { graphql, useStaticQuery } from 'gatsby';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { usePapaParse } from 'react-papaparse';
import { MapContextQuery } from '../../../graphql-types';
import { findTranslation } from '../../utils/findTranslation';
import { Expert } from '../section/MapSection';
import { usePageContext } from './pageContext';

type Coords = {
  lat: number;
  lng: number;
};

interface MapContext {
  expFilter: string[];
  setExpFilter: (exp: string[]) => void;
  selected: Expert | undefined;
  setSelected: (d: Expert | undefined) => void;
  panCoords: Coords | undefined;
  setPanCoords: (c: Coords) => void;
  filterInput: any;
  setFilterInput: (value: any) => void;
  mapAreaCords: any;
  setMapAreaCoords: any;
  expertsList: Expert[];
}

const MapContext = createContext<MapContext | undefined>(undefined);

export const MapContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { readRemoteFile } = usePapaParse();
  const { lang, defaultLang } = usePageContext();
  const [results, setResults] = useState<Expert[]>([]);
  const [expFilter, setExpFilter] = useState<string[]>([]);
  const [selected, setSelected] = useState<Expert | undefined>(undefined);
  const [panCoords, setPanCoords] = useState<{ lat: number; lng: number } | undefined>(undefined);
  const [filterInput, setFilterInput] = useState<string>('');
  const data = useStaticQuery<MapContextQuery>(QUERY);
  const { sanityMapSection } = data as MapContextQuery;

  const [mapAreaCords, setMapAreaCoords] = useState<
    { lat: { low: number; high: number }; lng: { low: number; high: number } } | undefined
  >({ lat: { low: 0, high: 1 }, lng: { low: 0, high: 1 } });

  const handleReadRemoteFile = () => {
    readRemoteFile(sanityMapSection?.expertsList?.asset?.url ?? '', {
      download: true,
      header: true,
      transformHeader: (_, index) => {
        return csvKeys[index];
      },
      complete: (results) => {
        const reformated = results.data.map((item: any) => {
          let expertiseList: any[] = [];
          try {
            expertiseList = item.expertise ? JSON.parse(item.expertise) : [];
          } catch (e) {}

          let expertises = '';
          for (let i = 0; i < expertiseList.length; i++) {
            // const expertise =
            const elem = sanityMapSection?.expertiseList?.find(
              (e) => e?.key?.toLowerCase() === expertiseList[i].toLowerCase(),
            );

            const trad = findTranslation(elem?._rawTitle, lang, defaultLang);

            i === 0
              ? (expertises = `${trad}`)
              : trad
              ? (expertises = `${expertises}, ${trad}`)
              : '';
          }

          const elem = sanityMapSection?.expertiseList?.find(
            (e) => e?.key?.toLowerCase() === expertiseList[0]?.toLowerCase(),
          );
          const color = elem?.colorNav ?? 'orange';
          return {
            ...item,
            expertise: expertiseList,
            expertises,
            color,
          };
        });
        const complete = reformated.filter(
          (item) => item.latitude && item.longitude && item.address,
        );
        setResults(complete as Expert[]);
      },
    });
  };

  const areaFilteredResults = useMemo(
    () =>
      results.filter((item) => {
        if (
          mapAreaCords === undefined ||
          isNaN(mapAreaCords?.lat?.low) ||
          isNaN(mapAreaCords?.lat?.high)
        )
          return true;
        const latitude = parseFloat(item.latitude.replace(',', '.'));
        const longitude = parseFloat(item.longitude.replace(',', '.'));

        const isLatOk = latitude >= mapAreaCords?.lat?.low && latitude <= mapAreaCords?.lat?.high;
        const isLngOk = longitude >= mapAreaCords?.lng?.low && longitude <= mapAreaCords?.lng?.high;

        return isLatOk && isLngOk;
      }),
    [results, mapAreaCords],
  );

  const filteredResults = useMemo(
    () =>
      expFilter.length === 0
        ? areaFilteredResults
        : areaFilteredResults.filter((item) =>
            expFilter.some((exp) => item.expertise.indexOf(exp) !== -1),
          ),
    [expFilter, areaFilteredResults],
  );

  useEffect(() => {
    handleReadRemoteFile();
  }, []);

  useEffect(() => {
    if (selected)
      setPanCoords({
        lat: parseFloat(selected.latitude.replace(',', '.')),
        lng: parseFloat(selected.longitude.replace(',', '.')),
      });
  }, [selected]);

  return (
    <MapContext.Provider
      value={{
        expFilter,
        setExpFilter,
        selected,
        setSelected,
        panCoords,
        setPanCoords,
        filterInput,
        setFilterInput,
        mapAreaCords,
        setMapAreaCoords,
        expertsList: filteredResults,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export const useMapContext = () => {
  const context = useContext(MapContext);
  if (!context) throw new Error('No MapContext.Provider found when calling useWindowSizeContext');
  return context;
};

const csvKeys = [
  'id',
  'practiceName',
  'phone',
  'expertise',
  'address',
  'city',
  'zipcode',
  'country',
  'latitude',
  'longitude',
];

const QUERY = graphql`
  query mapContext {
    sanityMapSection {
      ...mapSectionFragment
    }
  }
`;
