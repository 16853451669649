import React, { useState } from 'react';
import { usePageContext } from '../context/pageContext';
import * as styles from './subMenuDesktop.module.scss';
import { LocalLink } from '../html-tag/localLink';
import ArrowDownOrange from '../../../static/svg/arrow-down-orange.svg';
import {
  SanityCountrySettings,
  SanityMenuItem,
  SanityPage,
  SanityPageExpertise,
} from 'graphql-types';
import { constructSlug } from '../../utils/constructSlug';
import { SubTechMenuDesktop } from './SubTechMenuDesktop';
import { findTranslation } from '../../utils/findTranslation';
import { SanityMenuSubItem } from '../../../graphql-types';
import { useGeoContext } from '../context/useGeoContext';
import { isCountryAllowed } from '../../utils/isCountryAllowed';

interface Props {
  data: SanityMenuItem;
}

export const SubMenuDesktop: React.FC<Props> = ({ data }) => {
  const { lang, defaultLang } = usePageContext();
  const { country } = useGeoContext();
  const [clikedItem, setClikedItem] = useState(null);

  const handleClickOnSubTechMenu = (item) => {
    if (item === clikedItem) {
      setClikedItem(null);
      return;
    }
    setClikedItem(item);
  };

  const allLink = data?.correspondingInternalPage?.allSubPages ? (
    <li>
      <LocalLink
        to={
          constructSlug({
            destination: data.correspondingInternalPage.correspondingPage as
              | SanityPage
              | SanityPageExpertise,
          }) ?? ''
        }
      >
        {data?.correspondingInternalPage?._rawAllLinkName?.[lang] ?? 'All'}
      </LocalLink>
    </li>
  ) : null;

  return (
    <div className={styles.wrapper}>
      <ul>
        {data?.correspondingInternalPage?.allLinkPosition === 'top' && allLink}
        {data?.correspondingInternalPage?.menuSubItemsList
          ?.filter((item) =>
            isCountryAllowed(
              country,
              item?.correspondingSubPage?.countrySettings as SanityCountrySettings,
            ),
          )
          ?.map((menuSubItem, index) =>
            (menuSubItem?.correspondingSubPageProduct?.length ?? 0) > 0 ? (
              <div key={`menuSubItem--${index}`}>
                <div
                  className={
                    clikedItem ===
                    findTranslation(menuSubItem?._rawLabelSubItem, defaultLang, defaultLang)
                      ? styles.arrowDown
                      : styles.arrowUp
                  }
                  onClick={() =>
                    handleClickOnSubTechMenu(
                      findTranslation(menuSubItem?._rawLabelSubItem, defaultLang, defaultLang),
                    )
                  }
                >
                  {findTranslation(menuSubItem?._rawLabelSubItem, lang, defaultLang)}
                  <ArrowDownOrange />
                </div>
                {clikedItem ===
                  findTranslation(menuSubItem?._rawLabelSubItem, defaultLang, defaultLang) && (
                  <SubTechMenuDesktop menuSubItem={menuSubItem as SanityMenuSubItem} />
                )}
              </div>
            ) : (
              <li key={`menuSubItem-${index}`}>
                <LocalLink
                  to={
                    constructSlug({
                      destination: menuSubItem?.correspondingSubPage as SanityPageExpertise,
                    }) ?? ''
                  }
                  className={styles.menuSubItem}
                >
                  {findTranslation(menuSubItem?._rawLabelSubItem, lang, defaultLang)}
                </LocalLink>
              </li>
            ),
          )}
        {data?.correspondingInternalPage?.allLinkPosition === 'bottom' && allLink}
      </ul>
    </div>
  );
};
