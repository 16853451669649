import './src/sass/global.scss';
import React from 'react';
import { PageContextProvider } from './src/components/context/pageContext';
import { WindowSizeContextProvider } from './src/components/context/useWindowSizeContext';
import { Layout } from './src/components/layout/Layout';
import { MapContextProvider } from './src/components/context/mapContext';
import { XpContextProvider } from './src/components/context/xpContext';
import { GeoContextProvider } from './src/components/context/useGeoContext';

export const wrapPageElement = ({ element, props }) => {
  return (
    <PageContextProvider pageContext={props.pageContext}>
      <GeoContextProvider>
        <WindowSizeContextProvider>
          <MapContextProvider>
            <XpContextProvider>
              <Layout>{element}</Layout>
            </XpContextProvider>
          </MapContextProvider>
        </WindowSizeContextProvider>
      </GeoContextProvider>
    </PageContextProvider>
  );
};
