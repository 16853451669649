/** refacto lang */
import { Maybe, SanityFooter, SanityPage } from 'graphql-types';
import React, { useState } from 'react';
import * as styles from './accordion.module.scss';
import ArrowDownWhite from '../../../static/svg/arrow-down-white.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebook,
  faFacebookF,
  faYoutube,
  faTwitter,
  faTwitch,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { usePageContext } from '../context/pageContext';
import { LocalLink } from '../html-tag/localLink';
import slugify from 'slugify';
import { findTranslation } from '../../utils/findTranslation';
import { constructSlug } from '../../utils/constructSlug';
import { SanityPageExpertise } from '../../../graphql-types';

interface Props {
  data: SanityFooter;
}

library.add(faFacebook, faFacebookF, faYoutube, faTwitter, faTwitch, faInstagram, faLinkedin);

export const Accordion: React.FC<Props> = ({ data }) => {
  const { lang, defaultLang } = usePageContext();
  const [isOpened, setIsOpened] = useState<number>(-1);

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.title}
        onClick={() => setIsOpened(isOpened === 0 ? -1 : 0)}
      >
        {findTranslation(data?._rawTitleCompany, lang, defaultLang)}
        <ArrowDownWhite />
      </div>
      {isOpened == 0 && (
        <div className={styles.address}>
          <p>{data?.addressCompany}</p>
          <p>{data?.phoneCompany}</p>
          <p>{data?.emailCompany}</p>
        </div>
      )}
      <div
        className={styles.title}
        onClick={() => setIsOpened(isOpened === 1 ? -1 : 1)}
      >
        {findTranslation(data?._rawTitleAboutUs, lang, defaultLang)}
        <ArrowDownWhite />
      </div>
      {isOpened == 1 && (
        <ul>
          {data?.MenuAboutUs?.menuItemsList?.map((menuItem, index) => {
            let slug: Maybe<string> | undefined;

            if (menuItem?.correspondingInternalPage?.experience) slug = `/experience`;
            else
              slug = constructSlug({
                destination: menuItem?.correspondingInternalPage?.correspondingPage as
                  | SanityPage
                  | SanityPageExpertise,
              });
            return (
              <li key={`menuItem-${index}`}>
                <LocalLink to={slug ?? ''}>
                  {findTranslation(menuItem?._rawLabelItem, lang, defaultLang)}
                </LocalLink>
              </li>
            );
          })}
        </ul>
      )}
      <div
        className={styles.title}
        onClick={() => setIsOpened(isOpened === 2 ? -1 : 2)}
      >
        {findTranslation(data?._rawTitleSocial, lang, defaultLang)}
        <ArrowDownWhite />
      </div>
      {isOpened == 2 && (
        <ul>
          {data?.socialsLinkListFooter?.map((social, index) => {
            return (
              <li>
                <a
                  href={social?.socialUrl?.urlButton ?? ''}
                  target={social?.socialUrl?.target ?? ''}
                  key={`social-${index}`}
                  className={styles.social}
                >
                  <FontAwesomeIcon icon={['fab', (social?.socialPicker ?? '') as any]} />
                  {social?.socialPicker}
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
