/** refacto lang */
import { graphql, useStaticQuery } from 'gatsby';
import { FooterQuery, Maybe, SanityFooter, SanityPage, SanityPageExpertise } from 'graphql-types';
import * as styles from './footerDesktop.module.scss';
import React from 'react';
import { Newsletter } from '../newsletter/Newsletter';
import { usePageContext } from '../context/pageContext';
import { CTA } from '../html-tag/Cta';
import { LocalLink } from '../html-tag/localLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebook,
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitch,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { GatsbyImage } from 'gatsby-plugin-image';
import { findTranslation } from '../../utils/findTranslation';
import { constructSlug } from '../../utils/constructSlug';

interface Props {}

library.add(faFacebook, faFacebookF, faYoutube, faTwitter, faTwitch, faInstagram, faLinkedin);

export const FooterDesktop: React.FC<Props> = () => {
  const data = useStaticQuery<FooterQuery>(FOOTERDESKTOP);

  const { lang, defaultLang } = usePageContext();
  return (
    <div className={styles.wrapper}>
      <div className={styles.first}>
        <div className={styles.content}>
          <div className={styles.box}>
            <h2>{findTranslation(data?.sanityFooter?._rawTitleCompany, lang, defaultLang)}</h2>
            <div className={styles.company}>
              <p>{data?.sanityFooter?.addressCompany}</p>
              <p>{data?.sanityFooter?.phoneCompany}</p>
              <p>{data?.sanityFooter?.emailCompany}</p>
            </div>
          </div>

          <div className={styles.box}>
            <h2>{findTranslation(data?.sanityFooter?._rawTitleAboutUs, lang, defaultLang)}</h2>
            <div>
              {data?.sanityFooter?.MenuAboutUs?.menuItemsList?.map((menuItem, index) => {
                let slug: Maybe<string> | undefined;

                if (menuItem?.correspondingInternalPage?.experience) slug = '/experience';
                else
                  slug = constructSlug({
                    destination: menuItem?.correspondingInternalPage?.correspondingPage as
                      | SanityPage
                      | SanityPageExpertise,
                  });
                return (
                  <div key={`menuItem-${index}`}>
                    <LocalLink to={slug ?? ''}>
                      {findTranslation(menuItem?._rawLabelItem, lang, defaultLang)}
                    </LocalLink>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.box}>
            <h2>{findTranslation(data?.sanityFooter?._rawTitleSocial, lang, defaultLang)}</h2>
            <div>
              {data?.sanityFooter?.socialsLinkListFooter?.map((social, index) => {
                return (
                  <div key={`social-${index}`}>
                    <a
                      href={social?.socialUrl?.urlButton ?? ''}
                      target={social?.socialUrl?.target ?? ''}
                      key={`social-${index}`}
                      className={styles.social}
                    >
                      <FontAwesomeIcon icon={['fab', (social?.socialPicker ?? '') as any]} />
                      {social?.socialPicker}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.news}>
          <Newsletter data={data?.sanityFooter as SanityFooter} />
        </div>
      </div>
      <div className={styles.second}>
        <div className={styles.secondContent}>
          <CTA
            label={findTranslation(data?.sanityFunctionButton?._rawSpecialist, lang, defaultLang)}
            to={
              constructSlug({ destination: data?.sanityFooter?.findTherapistPage as SanityPage }) ??
              ''
            }
            dataType={'orange'}
          />
          <CTA
            label={findTranslation(data?.sanityFunctionButton?._rawContactUs, lang, defaultLang)}
            to={constructSlug({ destination: data?.sanityFooter?.contactPage as SanityPage }) ?? ''}
            dataType={'black'}
          />
        </div>
        <div className={styles.logo}>
          <LocalLink to={'/'}>
            <GatsbyImage
              alt={data?.sanityFooter?.logoCompanyFooter?.logoAlt ?? ''}
              image={data?.sanityFooter?.logoCompanyFooter?.logoImg?.asset?.gatsbyImageData ?? ''}
            />
          </LocalLink>
          <p>{data.sanityFooter?.copyRightCompany}</p>
        </div>
      </div>
    </div>
  );
};

export const FOOTERDESKTOP = graphql`
  query FooterDesktop {
    sanityFunctionButton {
      _rawContactUs(resolveReferences: { maxDepth: 10 })
      _rawSpecialist(resolveReferences: { maxDepth: 10 })
    }
    sanityFooter {
      ...footerFragment
    }
  }
`;
