import axios from 'axios';

export interface GetLocationProps {
  ip: string;
}

export const getGeolocation = async ({ ip }: GetLocationProps) => {
  try {
    let country = '';

    await fetch(`/.netlify/functions/getCountry`, {
      method: 'POST',
      body: JSON.stringify(ip),
    })
      .then(async (res) => await res.json())
      .then((res) => {
        country = res.country;
      });
    // if (res.status === 200) {
    return country;
    // }
  } catch (e) {
    console.log('error in getGeolocation', e);
  }
  return null;
};
